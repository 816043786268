import styled, { css } from 'styled-components'

export const StyledP = styled.p`
  color: #1F1F1F;
  text-align: left;

  ${props => props.white && css`
    color: white;
  `}

  ${props => props.centered && css`
    text-align: center;
  `}
`
