import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'
import { shade } from 'polished'

export const Container = styled.div`
  padding: 2rem 1rem;
  background-color: #BF2D38;
  box-sizing: border-box;
  order: 2;
  flex: 0 0 100%;

  ${breakpoint('tablet')`
    padding: 2rem;
    border-radius: 0.5rem;
    order: 1;
  `}

  ${breakpoint('desktop')`
    padding: 2rem;
    flex: 0 0 calc(50% - 1rem);
    margin-right: 1rem;
    border-radius: 0.5rem;
  `}
`

export const Datum = styled.div`
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${breakpoint('tablet')`
    text-align: left;
  `}
`

export const Title = styled.span`
  display: block;
  font-family: "Merit";
  text-transform: uppercase;
  color: ${shade(0.5, '#BF2D38')};
  margin-bottom: 0.5rem;

  ${breakpoint('tablet')`
    letter-spacing: 1px;
  `}

  ${breakpoint('desktop')`
    letter-spacing: 2px;
  `}
`

export const Content = styled.p`
  /* font-size: 1.4rem; */
  color: white;
  margin: 0;
  margin-bottom: 1rem;
`

export const A = styled.a`
  color: white;
  word-wrap: break-word;
`
