import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Container = styled.div`
  padding: 0;
  margin: 0;
  background-color: white;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  ${breakpoint('tablet')`
    padding: 0 2rem;
    margin: 2rem 0;
    align-items: flex-start;
    justify-content: center;
  `}

  ${breakpoint('desktop')`
    padding: 0 6rem;
    margin: 3rem 0 6rem 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  `}
`
