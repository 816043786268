import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint'

export const Container = styled.div`
  background-color: white;
  padding: 0 1rem;
  margin: 2rem 0;

  ${breakpoint('tablet')`
    padding: 0 2rem;
    margin: 3rem 0;
  `}

  ${breakpoint('desktop')`
    padding: 0 6rem;
    margin: 6rem 0 3rem 0;
  `}
`
