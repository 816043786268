import React from 'react'
import { Container, Datum, Title, Content, A } from './styles'

const ContactDetails = () => (
  <Container>
    <Datum>
      <Title>Address</Title>
      <Content>32 Albert Road, Evesham, Worcestershire, WR11 4JY</Content>
    </Datum>

    <Datum>
      <Title>Telephone</Title>
      <Content><A href="tel:+441386443223">01386 443223</A></Content>
    </Datum>

    {/* <Datum>
      <Title>Mobile (Colin)</Title>
      <Content>07850 226667</Content>
    </Datum> */}

    <Datum>
      <Title>Mobile {/* (Paul) */}</Title>
      <Content><A href="tel:+447793017803">07793017803</A> or <A href="tel:+447850226667">07850226667</A></Content>
    </Datum>

    <Datum>
      <Title>Email Address</Title>
      <Content><A href="mailto:paul@colincookecarpets.co.uk">paul@colincookecarpets.co.uk</A></Content>
    </Datum>
  </Container>
)

export default ContactDetails
