import React from 'react'
import MainLayout from '../layouts/main-layout'
import SEO from '../components/seo'
import ContactDetails from '../components/contact-details'
// import ContactForm from '../components/contact-form'
import { P } from '../components/typography'
import { TopDiv, BottomDiv } from '../components/contact'

const ContactPage = () => (
  <MainLayout titleText="Contact Us">
    <SEO title="Contact Us" />

    <TopDiv>
      <P centered>For a free home visit and quotation, you can contact us easily using any of the methods below and we will be more than happy to help.</P>
    </TopDiv>

    <BottomDiv>
      <ContactDetails />
      {/* <ContactForm /> */}
    </BottomDiv>
  </MainLayout>
)

export default ContactPage
